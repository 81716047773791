import React from 'react';
import Prismic from 'prismic-javascript';
import { ArticleList, Slice } from 'component-studio';
import { FinderContext } from '../contexts/Finder';
import { Date } from 'prismic-reactjs';
import { format } from 'date-fns';

function cleanArticles(items = []) {
  return items.map(item => ({
    key: item.id,
    ...(item && item.data && item.data.title && { title: item.data.title }),
    ...(item &&
      item.data &&
      item.data.date && {
        date: format(Date(item.data.date), 'DD MMM YYYY')
      }),
    ...(item && item.data && item.data.category
      ? { category: '??' }
      : { category: 'Article' }),
    link: { url: `/article/${item.uid}` }
  }));
}

export default function PrismicArticleList({ title }) {
  const [articles, setArticles] = React.useState([]);
  const finder = React.useContext(FinderContext);

  React.useEffect(() => {
    async function getArticles() {
      const articles = await finder.query(
        Prismic.Predicates.at('document.type', 'article'),
        { pageSize: 10, page: 1, orderings: '[my.article.date desc]' }
      );

      if (articles && articles.results) {
        setArticles(cleanArticles(articles.results));
      }
    }
    getArticles();
  }, [finder]);

  return (
    <Slice title={title}>
      <ArticleList items={articles} />
    </Slice>
  );
}
