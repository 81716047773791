import React from 'react';
import { ArticleContent } from 'component-studio';
import RichText from '../components/RichText';

export default function PrismicArticleContent({ content }) {
  return (
    <ArticleContent>
      <RichText serialize>{content}</RichText>
    </ArticleContent>
  );
}
