import React from 'react';
import Cookies from 'js-cookie';
import { PrismicContext } from './PrismicAPI';

const COOKIE_NAME = 'lang';

const labels = {
  'fr-fr': 'Français',
  'en-gb': 'English'
};

/**
 * Retrieve and clean languages from prismic api
 * @param {object} api
 * @param {object} api.data
 * @param {array} api.data.languages
 */
function getLanguages(api = {}) {
  return api.data && api.data.languages && api.data.languages.length > 0
    ? api.data.languages.map(language => ({
        ID: language.id,
        label: labels[language.id] || language.name
      }))
    : [];
}

/**
 * Return the current lang used by the user
 * @param {array} languages
 */
function getCurrentLang(languages = []) {
  // Get langID in cookie or from navigator
  let langID =
    Cookies.get(COOKIE_NAME) ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language;

  // Test if this is available and a well formed langID
  // Take first language as default language
  if (
    !langID ||
    !/^[a-z]{2}-[a-zA-Z]{2}$/.test(langID) ||
    !languages.find(language => language.ID === langID)
  )
    langID = languages[0] ? languages[0].ID : 'fr-FR';

  // fr-FR ==> fr-fr
  langID = langID.toLowerCase();

  return {
    ID: langID,
    label: labels[langID] || langID
  };
}

const LanguageContext = React.createContext();

function LanguageProvider({ children }) {
  const prismic = React.useContext(PrismicContext);
  const languages = getLanguages(prismic);
  const lang = getCurrentLang(languages);

  function setLang(lang) {
    Cookies.set(COOKIE_NAME, lang);
    window.location = '/';
  }

  return (
    <LanguageContext.Provider value={{ lang, languages, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext, LanguageProvider };
