import React from 'react';
import { FinderContext } from './Finder';

const LayoutContext = React.createContext({});

function LayoutProvider({ children }) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [layout, setLayout] = React.useState(null);

  const finder = React.useContext(FinderContext);

  React.useEffect(() => {
    async function getLayout() {
      try {
        const layout = await finder.getSingle('layout');
        if (!layout || !layout.data) throw new Error('No layout found');
        setLayout(layout.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    getLayout();
  }, [finder]);

  if (loading) return null;
  if (error) throw error;

  return (
    <LayoutContext.Provider value={layout}>{children}</LayoutContext.Provider>
  );
}

export { LayoutContext, LayoutProvider };
