import React from 'react';
import { RoundedCards } from 'component-studio';
import linkResolver from '../linkResolver';

export default function PrismicRoundedCards({
  items,
  title,
  action_target,
  action_label
}) {
  const cleanCards = items.map(
    ({ image, title, text, action_target, action_label }) => {
      return {
        image: {
          src: image.url,
          alt: image.alt
        },
        title: title,
        text: text,
        action: action_target
          ? {
              url: linkResolver(action_target),
              label: action_label
            }
          : null
      };
    }
  );

  const action = {
    url: linkResolver(action_target),
    label: action_label
  };

  return <RoundedCards cards={cleanCards} title={title} action={action} />;
}
