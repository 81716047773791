/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Iframe } from 'component-studio';

export default function IframeSlice(slice) {
  if (!slice || !slice.link || !slice.link.url) return null;
  return (
    <div css={{ padding: '30px 0' }}>
      <Iframe title={slice.title} url={slice.link.url} />
    </div>
  );
}
