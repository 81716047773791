import React from 'react';
import { ThemeProvider as InitialThemeProvider } from 'component-studio';
import { LayoutContext } from './Layout';

/**
 * From layout document, retrieve the theme
 * @param {object} layout
 */
function getThemeFromLayout({
  primary_color: primary,
  secondary_color: secondary,
  third_color: third,
  fourth_color: fourth,
  fifth_color: fifth,
  sixth_color: sixth
}) {
  return {
    colors: {
      ...(primary ? { primary } : {}),
      ...(secondary ? { secondary } : {}),
      ...(third ? { third } : {}),
      ...(fourth ? { fourth } : {}),
      ...(fifth ? { fifth } : {}),
      ...(sixth ? { sixth } : {})
    }
  };
}

function ThemeProvider({ children }) {
  const layout = React.useContext(LayoutContext);

  return (
    <InitialThemeProvider theme={getThemeFromLayout(layout)}>
      {children}
    </InitialThemeProvider>
  );
}

export default ThemeProvider;
