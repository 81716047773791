import React from 'react';
import { NavigationBanner } from 'component-studio';
import linkResolver from '../linkResolver';

export default function PrismicNavigationBanner({ items, title, background }) {
  const cleanCards = items.map(({ card_image, card_link, card_title }) => {
    return {
      image: {
        src: card_image.url,
        alt: card_image.alt
      },
      target: linkResolver(card_link),
      title: card_title
    };
  });

  return (
    <NavigationBanner
      cards={cleanCards}
      title={title}
      backgroundColor={background}
    />
  );
}
