import React from 'react';
import { ServiceSelector } from 'component-studio';
import { FinderContext } from '../contexts/Finder';
import RichText from '../components/RichText';
import linkResolver from '../linkResolver';

function flatten(array = []) {
  return array.reduce((acc, curr) => {
    acc.push(...curr);
    return acc;
  }, []);
}

function categoriesFromServices(services = []) {
  return services.map(service => service.name);
}

function cardsFromServices(services = []) {
  return flatten(
    services.map(({ name, color, cards }, key) =>
      cards.map((card, key2) => ({
        key: `${key}${key2}`,
        category: name,
        colorKey: color,
        title: card.title,
        content: <RichText>{card.content}</RichText>,
        action: card.target
          ? {
              label: card.label || 'En savoir plus',
              url: linkResolver(card.target)
            }
          : null
      }))
    )
  );
}

export default function PrismicServiceCards({ title, items }) {
  const finder = React.useContext(FinderContext);
  const [categories, setCategories] = React.useState([]);
  const [cards, setCards] = React.useState([]);

  React.useEffect(() => {
    async function getServices() {
      const documents = await Promise.all(
        items.map(item => finder.getByID(item.service_card_list.id))
      );

      const services = documents.map(document => document.data);
      setCategories(categoriesFromServices(services));
      setCards(cardsFromServices(services));
    }
    getServices();
  }, [finder, items]);

  if (categories.length <= 0 || cards.length <= 0) return null;

  return (
    <ServiceSelector title={title} categories={categories} cards={cards} />
  );
}
