import React from 'react';
import linkResolver from './linkResolver';
import { RichText } from 'prismic-reactjs';
import { ArticleElements } from 'component-studio';

const Elements = RichText.Elements;

/**
 * Transform each parts of a prismic rich text into a eb-component
 */
export default function RichTextSerializer(
  type,
  element,
  content,
  children,
  key
) {
  switch (type) {
    case Elements.heading2:
      return (
        <ArticleElements.Heading2 key={key}>
          {children}
        </ArticleElements.Heading2>
      );

    case Elements.heading3:
      return (
        <ArticleElements.Heading3 key={key}>
          {children}
        </ArticleElements.Heading3>
      );

    case Elements.heading4:
      return (
        <ArticleElements.Heading4 key={key}>
          {children}
        </ArticleElements.Heading4>
      );

    case Elements.heading5:
      return (
        <ArticleElements.Heading5 key={key}>
          {children}
        </ArticleElements.Heading5>
      );

    case Elements.heading6:
      return (
        <ArticleElements.Heading6 key={key}>
          {children}
        </ArticleElements.Heading6>
      );

    case Elements.paragraph:
      return (
        <ArticleElements.Paragraph key={key}>
          {children}
        </ArticleElements.Paragraph>
      );

    case Elements.image:
      return (
        <ArticleElements.Image
          src={element.url}
          alt={element.alt || ''}
          key={key}
        />
      );

    case Elements.strong:
      return (
        <ArticleElements.Strong key={key}>{children}</ArticleElements.Strong>
      );

    case Elements.em:
      return <ArticleElements.Em key={key}>{children}</ArticleElements.Em>;

    case Elements.listItem:
      return (
        <ArticleElements.ListItem key={key}>
          {children}
        </ArticleElements.ListItem>
      );

    case Elements.oListItem:
      return (
        <ArticleElements.ListItem key={key}>
          {children}
        </ArticleElements.ListItem>
      );

    case Elements.list: // Unordered List
      return <ArticleElements.List key={key}>{children}</ArticleElements.List>;

    case Elements.oList: // Ordered List
      return (
        <ArticleElements.OList key={key}>{children}</ArticleElements.OList>
      );

    case Elements.hyperlink:
      return (
        <ArticleElements.Hyperlink href={linkResolver(element.data)} key={key}>
          {children}
        </ArticleElements.Hyperlink>
      );

    // Return null to stick with the default behavior
    default:
      return null;
  }
}
