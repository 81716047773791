import React from 'react';
import styled from '@emotion/styled';

// ! ThemeProvider is after this page, so the theme is not available in the styled components here

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: '#eee',
  padding: 15
});

const Container = styled('div')({
  width: '100%',
  maxWidth: 500,
  backgroundColor: '#fff',
  borderRadius: 4,
  boxShadow: 'rgba(0,0,0,0.4) 1px 1px 3px 0px',
  padding: 20
});

const Title = styled('div')({
  fontSize: 36,
  marginBottom: 20
});

const Content = styled('div')({
  fontSize: 18,
  lineHeight: 1.5
});

export default class InternalError extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(err, info) {
    // eslint-disable-next-line no-console
    console.error(err, info);
  }

  render() {
    if (!this.state || (this.state && !this.state.hasError))
      return this.props.children;

    return (
      <Wrapper>
        <Container>
          <Title>Error 500 : an error occurs</Title>
          <Content>
            We are sorry, but something broke on our side. Please retry again
            later, and contact an administrator if the problem persists
          </Content>
        </Container>
      </Wrapper>
    );
  }
}
