import React from 'react';
import { ReminderCTA } from 'component-studio';
import linkResolver from '../linkResolver';
import RichText from '../components/RichText';

export default function PrismicReminderCTA({
  title,
  content,
  background_image,
  label_link,
  link
}) {
  return (
    <ReminderCTA
      title={title}
      content={<RichText>{content}</RichText>}
      background={background_image.url}
      action={{ label: label_link, url: linkResolver(link) }}
    />
  );
}
