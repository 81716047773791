import React from 'react';
import { AsideCards, Slice } from 'component-studio';
import linkResolver from '../linkResolver';
import RichText from '../components/RichText';

function itemsToCards(items = []) {
  return items.map(
    ({ image, title, text_card, action_target, action_label }) => {
      return {
        image: {
          src: image.url,
          alt: image.alt
        },
        action: action_label
          ? {
              url: linkResolver(action_target),
              label: action_label
            }
          : null,
        title: title,
        text: text_card && <RichText>{text_card}</RichText>
      };
    }
  );
}

export default function PrismicAsideCards({ items }) {
  return (
    <Slice>
      <AsideCards cards={itemsToCards(items)} />
    </Slice>
  );
}
