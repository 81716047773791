import React from 'react';

import { PrismicContext } from './PrismicAPI';
import { LanguageContext } from './Language';

/**
 * Override prismic api with query already set up on the current language
 * @param {object} api Prismic API
 * @param {object} lang
 * @param {string} lang.ID
 */
function createFinder(api = {}, lang = {}) {
  return {
    query: (predicates, options = {}) =>
      api.query(predicates, { lang: lang.ID, ...options }),

    getByID: (id, options = {}) =>
      api.getByID(id, { lang: lang.ID, ...options }),

    getSingle: (type, options = {}) =>
      api.getSingle(type, { lang: lang.ID, ...options }),

    getByIDs: (ids, options = {}) =>
      api.getByIDs(ids, { lang: lang.ID, ...options }),

    getByUID: (type, uid, options = {}) =>
      api.getByUID(type, uid, { lang: lang.ID, ...options })
  };
}

export const FinderContext = React.createContext();

export function FinderProvider({ children }) {
  const prismic = React.useContext(PrismicContext);
  const { lang } = React.useContext(LanguageContext);
  return (
    <FinderContext.Provider value={createFinder(prismic, lang)}>
      {children}
    </FinderContext.Provider>
  );
}
