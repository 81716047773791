import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Link, GlobalStyles } from 'component-studio';
import ThemeProvider from './contexts/Theme';
import translations from './config/translations';
import { FinderProvider } from './contexts/Finder';
import { LayoutProvider } from './contexts/Layout';
import { LanguageProvider } from './contexts/Language';
import { PrismicProvider } from './contexts/PrismicAPI';
import { TranslationProvider } from './contexts/Translation';
import REPOSITORIES from './repositories';
import redirectsMatch from './redirectsMatch';

// Override each link of this website
function CustomLink({ href, children, ...props }) {
  if (!href) return null;

  // Change to target="_blank" if the link should open in a new tab
  if (/(^(https?|mailto|\/app|tel).*)|(.+\.(csv|xlsx|pdf)$)/.test(href)) {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
        {children}
      </a>
    );
  }

  // Use react-router-dom link be default (as this is a react app)
  return (
    <ReactLink to={href} {...props}>
      {children}
    </ReactLink>
  );
}

function getRepositoryName() {
  // Check if local env want to fix a repository
  if (process.env.REACT_APP_PRISMIC_REPOSITORY)
    return process.env.REACT_APP_PRISMIC_REPOSITORY;

  const hostname = window.location.hostname;

  // Test for known repositories
  const repository = REPOSITORIES.find(({ regex }) => regex.test(hostname));
  if (repository) return repository.name;

  // Or take the first part of the hostname and add studio
  // name-12flzej23.digitalstudio.website => name-studio
  const subdomain = hostname.split('.')[0] || '';
  const basicName = subdomain.split('-')[0] || '';
  return `${basicName}-studio`;
}
function getRedirectToUrl() {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0] || '';
  return redirectsMatch[subdomain];
}
export default function Providers({ children }) {
  return (
    <Link.ContextProvider element={CustomLink}>
      <PrismicProvider
        repository={getRepositoryName()}
        redirectTo={getRedirectToUrl()}
      >
        <LanguageProvider>
          <FinderProvider>
            <LayoutProvider>
              <ThemeProvider>
                <TranslationProvider translations={translations}>
                  <GlobalStyles />
                  {children}
                </TranslationProvider>
              </ThemeProvider>
            </LayoutProvider>
          </FinderProvider>
        </LanguageProvider>
      </PrismicProvider>
    </Link.ContextProvider>
  );
}
