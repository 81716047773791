import React from 'react';
import { QuickNews } from 'component-studio';
import { FinderContext } from '../contexts/Finder';
import linkResolver from '../linkResolver';

export default function PrismicQuickNews({
  title,
  action_target,
  action_label,
  items
}) {
  const finder = React.useContext(FinderContext);
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    async function getArticles() {
      const articles = await Promise.all(
        items.map(item =>
          item.article.id ? finder.getByID(item.article.id) : { data: {} }
        )
      );

      const cleanArticles = articles.map(article => ({
        title: article.data.title,
        content: article.data.resume,
        url: linkResolver(article)
      }));

      setArticles(cleanArticles);
    }

    getArticles();
  }, [finder, items]);

  const action = {
    url: linkResolver(action_target),
    label: action_label
  };

  return <QuickNews news={articles} title={title} action={action} />;
}
