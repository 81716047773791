import React from 'react';
import Cookies from 'js-cookie';
import qs from 'qs';
import Prismic from 'prismic-javascript';
import { PrismicContext } from '../contexts/PrismicAPI';
import { withRouter } from 'react-router-dom';
import linkResolver from '../linkResolver';

function Preview({ location, history }) {
  const prismic = React.useContext(PrismicContext);

  React.useEffect(() => {
    async function loadPreview() {
      const token = qs.parse(location.search.slice(1)).token;
      const url = await prismic.previewSession(token, linkResolver, '/');

      Cookies.set(Prismic.previewCookie, token, {
        expires: 1 / 48 // 30 minutes
      });

      history.push(url);
    }
    loadPreview();
  });

  return <p>Loading preview...</p>;
}

export default withRouter(Preview);
