import React from 'react';
import stringInject from 'stringinject';
import { LanguageContext } from './Language';

export const TranslationContext = React.createContext(_ => _);

export function TranslationProvider({ children, translations }) {
  const { lang = {} } = React.useContext(LanguageContext);

  function translate(textID, ...params) {
    const langTranslations = translations[lang.ID];
    if (!langTranslations) return textID;

    const translation = langTranslations[textID];
    if (!translation) return textID;

    return stringInject(translation, params);
  }

  return (
    <TranslationContext.Provider value={translate}>
      {children}
    </TranslationContext.Provider>
  );
}
