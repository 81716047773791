import React from 'react';
import { Helmet } from 'react-helmet';
import SlicesBuilder from '../SliceBuilder';
import Layout from '../components/Layout';

function Page({ body, seo_title, seo_description }) {
  return (
    <Layout>
      <Helmet>
        <title>{seo_title}</title>
        <meta name="description" value={seo_description} />
      </Helmet>
      <SlicesBuilder slices={body} />
    </Layout>
  );
}

Page.getInitialProps = async function getInitialProps(finder, params) {
  const document = await finder.getByUID('page', params.uid);
  if (!document) return null;
  return document.data;
};

export default Page;
