import React from 'react';
import { FourCards } from 'component-studio';
import linkResolver from '../linkResolver';
import RichText from '../components/RichText';

function getAvailableLinks(props) {
  const links = [];
  if (props.first_link && props.first_label_link)
    links.push({
      url: linkResolver(props.first_link),
      label: props.first_label_link
    });
  if (props.second_link && props.second_label_link)
    links.push({
      url: linkResolver(props.second_link),
      label: props.second_label_link
    });
  return links;
}

function itemsToCards(items = []) {
  return items.map(props => {
    const { image_card, title_card, text_card } = props;
    return {
      image: image_card.url,
      title: title_card,
      text: <RichText>{text_card}</RichText>,
      actions: getAvailableLinks(props)
    };
  });
}

export default function PrismicFourCards({ title, items }) {
  return <FourCards title={title} cards={itemsToCards(items)} />;
}
