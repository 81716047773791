function linkResolver(document = {}) {
  if (document.url) return document.url;
  switch (document.type) {
    case 'home':
      return '/';
    case 'page':
      return `/${document.uid}`;
    case 'article':
      return `/article/${document.uid}`;
    default:
      return null;
  }
}

export default linkResolver;
