import React from 'react';

import NavigationBanner from './prismifiedComponents/NavigationBanner';
import CuttingTitle from './prismifiedComponents/CuttingTitle';
import RoundedCards from './prismifiedComponents/RoundedCards';
import ArticleContent from './prismifiedComponents/ArticleContent';
import QuickNews from './prismifiedComponents/QuickNews';
import AsideCards from './prismifiedComponents/AsideCards';
import ThreeCards from './prismifiedComponents/ThreeCards';
import FourCards from './prismifiedComponents/FourCards';
import ContactCTA from './prismifiedComponents/ContactCTA';
import ReminderCTA from './prismifiedComponents/ReminderCTA';
import Banner from './prismifiedComponents/Banner';
import ArticleList from './prismifiedComponents/ArticleList';
import ServiceCards from './prismifiedComponents/ServiceCards';
import TabsContent from './prismifiedComponents/TabsContent';
import Iframe from './prismifiedComponents/Iframe';

// prismic_slice_id : PrismicComponent
const PrismicComponents = {
  navigation_banner: NavigationBanner,
  cutting_title: CuttingTitle,
  rounded_cards: RoundedCards,
  article_content: ArticleContent,
  quick_news: QuickNews,
  aside_cards: AsideCards,
  three_cards: ThreeCards,
  four_cards: FourCards,
  contact_cta: ContactCTA,
  banner: Banner,
  article_list: ArticleList,
  services_cards: ServiceCards,
  reminder_cta: ReminderCTA,
  tab_content: TabsContent,
  iframe: Iframe
};

export default function SliceBuilder({ slices = [] }) {
  return slices.map((slice, i) => {
    const Component = PrismicComponents[slice.slice_type];
    if (!Component) return null;
    return <Component items={slice.items} {...slice.primary} key={i} />;
  });
}
