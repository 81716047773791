import React from 'react';
import { ThreeCards } from 'component-studio';
import linkResolver from '../linkResolver';

export default function PrismicThreeCards({
  items,
  title,
  background_card_color
}) {
  const cleanCards = items.map(
    ({ image, title, text, action_target, action_label }) => {
      return {
        image: {
          src: image.url,
          alt: image.alt
        },
        title: title,
        text: text,
        action: action_label
          ? {
              url: linkResolver(action_target),
              label: action_label
            }
          : null
      };
    }
  );

  return (
    <ThreeCards
      backgroundCardColor={background_card_color}
      cards={cleanCards}
      title={title}
    />
  );
}
