import React from 'react';
import { Route } from 'react-router-dom';
import { FinderContext } from './contexts/Finder';
import NotFound from './pages/NotFound';

/**
 * This CustomRoute is a wrapper arround the page that add a key, even if we're not in an array
 * The reason is that each page use componentDidMount to fetch the data it needs.
 * But if you don't differenciate pages with a unique key, React don't remount them on the DOM tree
 * and componentDidMount is not called if you go on the same page, but with a different url.
 *
 * For exemple, got to /, then to /:uid, each page will mount.
 * But got to /:uid then to another /:uid, Page component will be call, but componentDidMount will
 * not be called the second time because the Page component is already on the DOM tree
 */

function PageFinder({ Page, match }) {
  const finder = React.useContext(FinderContext);
  const [loading, setLoading] = React.useState(!!Page.getInitialProps);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    async function getData() {
      if (!Page.getInitialProps) return null;

      try {
        const data = await Page.getInitialProps(finder, match.params);
        setData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, [Page, finder, match.params]);

  if (loading) return null;
  if (error) throw error;
  if (data === null) return <NotFound />;
  return <Page {...data} />;
}

export default function CustomRoute({ component, ...props }) {
  return (
    <Route
      {...props}
      render={routeProps => <PageFinder {...routeProps} Page={component} />}
    />
  );
}
