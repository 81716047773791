import React from 'react';
import { Banner } from 'component-studio';
import linkResolver from '../linkResolver';
import RichText from '../components/RichText';

export default function PrismicBanner({
  title,
  content,
  link,
  link_label,
  category,
  cover,
  background_color
}) {
  return (
    <Banner
      backgroundColor={background_color}
      title={title}
      subtitle={<RichText>{content}</RichText>}
      cover={cover && cover.url}
      category={category}
      action={{
        ...(link_label ? { url: linkResolver(link), label: link_label } : {})
      }}
    />
  );
}
