import React from 'react';
import { TabsContent } from 'component-studio';
import RichText from '../components/RichText';

export default function PrismicTopicContent({ background_color, items }) {
  const cleanItems = items.map(({ title, content }) => ({
    title,
    content: <RichText serialize>{content}</RichText>
  }));

  return (
    <TabsContent
      defaultIndex={0}
      backgroundColor={background_color}
      items={cleanItems}
    />
  );
}
