import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './CustomRoute';
import Providers from './Providers';

import Page from './pages/Page';
import Home from './pages/Home';
import Article from './pages/Article';
import Preview from './pages/Preview';
import NotFound from './pages/NotFound';
import InternalError from './pages/InternalError';

export default function App() {
  return (
    <BrowserRouter>
      <InternalError>
        <Providers>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/_preview" component={Preview} />
            <Route path="/article/:uid" component={Article} />
            <Route path="/:uid" component={Page} />
            <Route component={NotFound} />
          </Switch>
        </Providers>
      </InternalError>
    </BrowserRouter>
  );
}
