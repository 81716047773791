/**
 * List of available prismic repositories.
 * Each row contains these 2 items:
 *  - The name of the prismic repository
 *  - The regex used on window.location.hostname to get the prismic repository to use
 * Be aware that theses value get override by REACT_APP_PRISMIC_REPOSITORY in the .env file
 */

export default [
  { name: 'standalone-studio', regex: /localhost/ },
  { name: 'phaares', regex: /phaares/ },
  { name: 'l-oreal', regex: /l-oreal/ },
  { name: 'saint-louis-sucre', regex: /saint-louis-sucre/ },
  { name: 'demo-delegation-1', regex: /delegation-1/ },
  { name: 'demo-delegation-2', regex: /delegation-2/ },
  { name: 'cora', regex: /cora/ },
  { name: 'standalone-studio', regex: /studio-/ }
]
