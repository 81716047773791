import React from 'react';
import { CuttingTitle } from 'component-studio';
import linkResolver from '../linkResolver';

export default function PrismicCuttingTitle({
  title,
  background,
  image,
  action_target,
  action_label
}) {
  const cleanImage = {
    src: image.url,
    alt: image.alt
  };

  const action = {
    url: linkResolver(action_target),
    label: action_label
  };

  return (
    <CuttingTitle
      title={title}
      image={cleanImage}
      action={action}
      background={background}
    />
  );
}
