import { RichText } from 'prismic-reactjs';
import RichTextSerializer from '../RichTextSerializer';
import linkResolver from '../linkResolver';

export default function PrismicRichText({ serialize, children }) {
  return RichText.render(
    children,
    linkResolver,
    serialize ? RichTextSerializer : null
  );
}
