import React from 'react';
import { Helmet } from 'react-helmet';

// import getPrismicData from '../PrismicQuery';
import SlicesBuilder from '../SliceBuilder';
import Layout from '../components/Layout';

function Home({ title, description, body }) {
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <SlicesBuilder slices={body} />
    </Layout>
  );
}

Home.getInitialProps = async function getInitialProps(finder) {
  const document = await finder.getSingle('home');
  if (!document) return null;
  return document.data;
};

export default Home;
// export default getPrismicData(Home, getInitialProps);
