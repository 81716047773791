export default {
  accor1: 'https://www.ma-protectionsociale.fr/mapso/accor',
  accor2: 'https://entreprise.ma-protectionsociale.fr/mapso/accor2',
  afd: 'https://www.ma-protectionsociale.fr/mapso/AFD',
  ageo1: 'https://www.ma-protectionsociale.fr/mapso/Ageo1',
  ageo2: 'https://www.ma-protectionsociale.fr/mapso/Ageo2',
  aon: 'https://www.ma-protectionsociale.fr/mapso/AON1',
  aon2: 'https://www.ma-protectionsociale.fr/mapso/AON2',
  aon3: 'https://www.ma-protectionsociale.fr/mapso/AON3',
  aon4: 'https://www.ma-protectionsociale.fr/mapso/AON4',
  aon5: 'https://entreprise.ma-protectionsociale.fr/mapso/AON5',
  aon6: 'https://entreprise.ma-protectionsociale.fr/mapso/AON6',
  arkea: 'https://www.ma-protectionsociale.fr/mapso/Arkea2',
  bnprealestate: 'https://www.ma-protectionsociale.fr/mapso/bnprealstate',
  'delegation-1': 'https://www.ma-protectionsociale.fr/mapso/Bouyguestelecom',
  capgemini2: 'https://www.ma-protectionsociale.fr/mapso/Capgemini1',
  'cap-gemini': 'https://entreprise.ma-protectionsociale.fr/mapso/Capgemini2',
  crit: 'https://www.ma-protectionsociale.fr/mapso/crit',
  delta: 'https://entreprise.ma-protectionsociale.fr/mapso/delta',
  disney2: 'https://www.ma-protectionsociale.fr/mapso/disney2',
  disney: 'http://entreprise.ma-protectionsociale.fr/mapso/disney1',
  edf: 'https://www.ma-protectionsociale.fr/mapso/EDF',
  electrifil: 'https://www.ma-protectionsociale.fr/mapso/electrifil',
  entreprise2: 'https://entreprise.ma-protectionsociale.fr/mapso/Essilor',
  filhetallard2: 'https://www.ma-protectionsociale.fr/mapso/FilhetAllard2',
  filhetallard: 'https://www.ma-protectionsociale.fr/mapso/FilhetAllard3',
  filhetallard5: 'https://www.ma-protectionsociale.fr/mapso/filhetallard5',
  gfp1: 'https://www.ma-protectionsociale.fr/mapso/GFP2',
  gfp2: 'https://www.ma-protectionsociale.fr/mapso/GFP3',
  grassavoye3: 'https://www.ma-protectionsociale.fr/mapso/Grassavoye3',
  grassavoye4: 'https://www.ma-protectionsociale.fr/mapso/Grassavoye4',
  grassavoye1: 'https://www.ma-protectionsociale.fr/mapso/Grassavoye1',
  grassavoye2: 'https://www.ma-protectionsociale.fr/mapso/Grassavoye2',
  henner1: 'https://www.ma-protectionsociale.fr/mapso/Henner1',
  henner2: 'https://www.ma-protectionsociale.fr/mapso/Henner2',
  henner3: 'https://www.ma-protectionsociale.fr/mapso/Henner3',
  henner4: 'https://www.ma-protectionsociale.fr/mapso/Henner4',
  igestion: 'https://www.ma-protectionsociale.fr/mapso/igestion',
  pharma: 'https://www.ma-protectionsociale.fr/mapso/IndustriePharma',
  itron: 'https://www.ma-protectionsociale.fr/mapso/Itron',
  johnson2: 'https://www.ma-protectionsociale.fr/mapso/Johnson2',
  'johnson-johnson':
    'https://entreprise.ma-protectionsociale.fr/mapso/Johnson1',
  kohler: 'https://www.ma-protectionsociale.fr/mapso/Kohler',
  metro1: 'https://www.ma-protectionsociale.fr/mapso/metro',
  metro2: 'https://entreprise.ma-protectionsociale.fr/mapso/metro2',
  miele: 'https://www.ma-protectionsociale.fr/mapso/MIELE',
  ogf: 'https://www.ma-protectionsociale.fr/mapso/ogf',
  pfizer: 'https://www.ma-protectionsociale.fr/mapso/pfizer',
  prysmian: 'https://www.ma-protectionsociale.fr/mapso/Prysmian-group',
  roederer: 'https://entreprise.ma-protectionsociale.fr/mapso/Roederer2',
  s2h: 'https://www.ma-protectionsociale.fr/mapso/S2H',
  semin: 'https://entreprise.ma-protectionsociale.fr/mapso/semin',
  'studio-serviviande':
    'https://www.ma-protectionsociale.fr/mapso/studio-serviviande',
  shisheido: 'https://www.ma-protectionsociale.fr/mapso/Shiseido',
  'delegation-2':
    'https://entreprise.ma-protectionsociale.fr/mapso/demo-entreprise/mes-services',
  ozy1: 'https://www.ma-protectionsociale.fr/mapso/SMATIS4',
  synergie: 'https://www.ma-protectionsociale.fr/mapso/Synergie',
  vinci2: 'https://entreprise.ma-protectionsociale.fr/mapso/vinci2',
  vinci: 'https://www.ma-protectionsociale.fr/mapso/vinci',
}
